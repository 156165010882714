// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {UserCalendarEventModel} from '@supermove/models';

// App
import ActionPanel from '@shared/design/components/Panel/ActionPanel';

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: flex-end;
`;

const Group = Styled.View`
  flex: 1;
`;

const UserCalendar = ({
  events,
  startTime,
  endTime,
}: {
  events: UserCalendarEventModel[];
  startTime: number;
  endTime: number;
}) => {
  return (
    <ActionPanel
      title={'Account Calendars'}
      BodyComponent={() => {
        return (
          <Column>
            {events.map((event, index) => (
              <></>
            ))}
          </Column>
        );
      }}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UserCalendar.fragment = gql`
  fragment UserCalendar on User {
    id
    calendarEvents(calendarId: $calendarId, startTime: $startTime, endTime: $endTime) {
      id
      calendarId
      busy
      description
      textDescription
      htmlLink
      readOnly
      status
      title
      whenType
      startTime
      endTime
      dateValue
      startDate
      endDate
    }
  }
`;

export default UserCalendar;
