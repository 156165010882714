// Libraries

// Supermove
import {gql} from '@supermove/graphql';
import {UserModel} from '@supermove/models';

// App
import EmailSenderKind from '@shared/modules/Organization/enums/EmailSenderKind';
import NavigationItems, {NavigationItem} from 'core/NavigationItems';

const getItems = ({viewer}: {viewer: UserModel}): NavigationItem[] => {
  return [
    {
      label: 'Profile',
      value: 'PROFILE',
      valueKey: 'section',
      items: [
        {
          label: 'Personal Information',
          value: '/account/profile/personal-information',
          valueKey: 'route',
        },
        {
          label: 'Notifications',
          value: '/account/profile/notifications',
          valueKey: 'route',
        },
      ],
    },
    ...NavigationItems.includeIf(
      viewer.viewingOrganization.features.isEnabledOfficeAccountEmailSettings &&
        viewer.viewingOrganization.settings.emailSenderKind === EmailSenderKind.USER,
      {
        label: 'Communication',
        value: 'COMMUNICATION',
        valueKey: 'section',
        items: [
          {
            label: 'Email',
            value: '/account/communication/email',
            valueKey: 'route',
          },
        ],
      },
    ),
    ...NavigationItems.includeIf(
      viewer.viewingOrganization.features.isEnabledOfficeAccountCalendar,
      {
        label: 'Scheduling',
        value: 'SCHEDULING',
        valueKey: 'section',
        items: [
          {
            label: 'Calendar',
            value: '/account/scheduling/calendar',
            valueKey: 'route',
          },
        ],
      },
    ),
  ];
};

// --------------------------------------------------
// Data
// --------------------------------------------------
getItems.fragment = gql`
  fragment UserSettingsNavigation_getItems on User {
    id
    viewingOrganization {
      id
      settings {
        id
        emailSenderKind
      }
      features {
        isEnabledOfficeAccountEmailSettings: isEnabled(feature: "OFFICE_ACCOUNT_EMAIL_SETTINGS")
        isEnabledOfficeAccountCalendar: isEnabled(feature: "OFFICE_ACCOUNT_CALENDAR")
      }
    }
  }
`;

const UserSettingsNavigation = {
  getItems,
};

export default UserSettingsNavigation;
