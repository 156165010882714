// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {UserCalendarModel} from '@supermove/models';

// App
import Button from '@shared/design/components/Button';
import Panel from '@shared/design/components/Panel';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: flex-end;
`;

const Group = Styled.View`
  flex: 1;
`;

const CalendarComponent = ({
  calendar,
  onSelectCalendar,
}: {
  calendar: UserCalendarModel;
  onSelectCalendar: (calendarId: string) => void;
}) => {
  return (
    <Row style={{alignItems: 'center'}}>
      <Group>
        <Panel.LabelText>{calendar.name}</Panel.LabelText>
      </Group>
      <Space width={8} />
      <Button
        isResponsive
        isSubmitting={false}
        onPress={() => onSelectCalendar(calendar.id)}
        text={'See Calendar'}
        iconSize={14}
      />
    </Row>
  );
};

const NoCalendars = () => (
  <Row>
    <Group>
      <Panel.LabelText>No Calendars</Panel.LabelText>
      <Space height={8} />
      <Panel.Text>Connect your email account to Supermove to sync your calendars.</Panel.Text>
    </Group>
  </Row>
);

const CalendarsPanel = ({
  calendars,
  onSelectCalendar,
}: {
  calendars: UserCalendarModel[];
  onSelectCalendar: (calendarId: string) => void;
}) => {
  return (
    <ActionPanel
      title={'Account Calendars'}
      BodyComponent={() => {
        if (calendars.length === 0) {
          return <NoCalendars />;
        }

        return (
          <Column>
            {calendars.map((calendar, index) => (
              <React.Fragment key={calendar.id}>
                {index > 0 && <Space height={16} />}
                <CalendarComponent calendar={calendar} onSelectCalendar={onSelectCalendar} />
              </React.Fragment>
            ))}
          </Column>
        );
      }}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CalendarsPanel.fragment = gql`
  fragment CalendarsPanel on User {
    id
    allCalendars {
      id
      name
    }
  }
`;

export default CalendarsPanel;
