// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';
import {UserModel} from '@supermove/models';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import NylasAccountPanel from 'modules/User/Settings/Communication/Email/components/NylasAccountPanel';
import CalendarsPanel from 'modules/User/Settings/Scheduling/Calendar/components/CalendarsPanel';
import UserSettingsPageWrapper from 'modules/User/Settings/components/UserSettingsPageWrapper';

const Content = Styled.View`
`;

const SchedulingCalendarContent = ({
  redirectUri,
  redirectPath,
  viewer,
  refetch,
}: {
  redirectUri: string;
  redirectPath: string;
  viewer: UserModel;
  refetch: () => void;
}) => {
  const {navigator} = useNavigationDOM();

  return (
    <Content>
      <NylasAccountPanel
        redirectUri={redirectUri}
        redirectPath={redirectPath}
        user={viewer}
        onDeactivate={() => refetch()}
        organizationId={viewer.viewingOrganization.id}
        userId={viewer.id}
      />
      <Space height={16} />
      <CalendarsPanel
        calendars={viewer.allCalendars}
        onSelectCalendar={(calendarId) => {
          navigator.push(`/account/scheduling/calendars/${calendarId}/view`);
        }}
      />
    </Content>
  );
};

const UserSettingsSchedulingCalendarPage = () => {
  const {loading, data, refetch} = useQuery(UserSettingsSchedulingCalendarPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {},
  });

  // Create redirect uri specific for this page.
  const redirectPath = '/account/scheduling/calendar';
  const redirectUrl = new URL(redirectPath, `${window.location.protocol}//${window.location.host}`);
  const redirectUri = redirectUrl.toString();

  return (
    <UserSettingsPageWrapper selectedLabel={'Scheduling/Calendar'} pageTitle={'Calendar'}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <SchedulingCalendarContent
            redirectUri={redirectUri}
            redirectPath={redirectPath}
            viewer={data.viewer}
            refetch={refetch}
          />
        )}
      </Loading>
    </UserSettingsPageWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UserSettingsSchedulingCalendarPage.query = gql`
  ${NylasAccountPanel.fragment}
  ${CalendarsPanel.fragment}
  query UserSettingsSchedulingCalendarPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
      }
      ...NylasAccountPanel
      ...CalendarsPanel
    }
  }
`;

export default UserSettingsSchedulingCalendarPage;
