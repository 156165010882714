// Libraries
import React, {useEffect} from 'react';

// Supermove
import {PageLoader, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useUrlFilters} from '@supermove/hooks';
import {UserCalendarEventModel} from '@supermove/models';
import {Datetime} from '@supermove/utils';

// App
import UserCalendar from 'modules/User/Settings/Scheduling/ViewCalendar/components/UserCalendar';
import UserSettingsPageWrapper from 'modules/User/Settings/components/UserSettingsPageWrapper';

const Content = Styled.View`
`;

const ViewUserCalendarContent = ({
  events,
  startTime,
  endTime,
}: {
  events: UserCalendarEventModel[];
  startTime: number;
  endTime: number;
}) => {
  return (
    <Content>
      <UserCalendar events={events} startTime={startTime} endTime={endTime} />
    </Content>
  );
};

const ViewUserCalendarPage = () => {
  const {params} = useNavigationDOM();
  const urlFilters = useUrlFilters({
    getRoute: () => `/account/scheduling/calendar/${params.calendarId}`,
    filterKeys: ['startTime', 'endTime'],
  });

  const startTime = urlFilters.get('startTime');
  const endTime = urlFilters.get('endTime');

  const {loading, data, refetch} = useQuery(ViewUserCalendarPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      calendarId: params.calendarId,
      startTime: startTime ?? Datetime.now.unix(),
      endTime: endTime ?? Datetime.now.add(30, 'days').unix(),
    },
  });

  useEffect(() => {
    refetch();
  }, [startTime, endTime]);

  return (
    <UserSettingsPageWrapper selectedLabel={'Scheduling/Calendar'} pageTitle={'Calendar'}>
      <PageLoader loading={loading} data={data}>
        {({loadedData}) => (
          <ViewUserCalendarContent
            events={loadedData.viewer.calendarEvents}
            startTime={startTime}
            endTime={endTime}
          />
        )}
      </PageLoader>
    </UserSettingsPageWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ViewUserCalendarPage.query = gql`
  ${UserCalendar.fragment}
  
  query ViewUserCalendarPage($calendarId: String!, $startTime: Int!, $endTime: Int!) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
      }
      ...UserCalendar
    }
  }
`;

export default ViewUserCalendarPage;
